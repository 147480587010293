export default class AccountModel {
    constructor(data) {
        this.UserId = data.UserId;
        this.Email = data.Email;

        if (data.EmailVerified) {
            this.EmailVerified = "Yes";
        }
        else {
            this.EmailVerified = "No";
        }
    }
};
