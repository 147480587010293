import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import ActivityModel from "./models/ActivityModel";
import { useAuth } from "../../contexts/AuthContext";

function ActivityEdit() {

    const { activityId } = useParams();
    const { currentUser } = useAuth();

    const [activity, setActivity] = useState({});

    const [state, setState] = useState({
        activityName: ""
    });

    useEffect(() => {
        const fetchActivity = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/activities/${activityId}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return new ActivityModel(result);
        };

        fetchActivity().then(activity => {
            setActivity(activity);
        });
    }, [activityId]);

    const handleChange = function (event) {
        setState({
            ...state, [event.target.name]: event.target.value
        });
    };

    const onCancel = function () {
        window.location.href = `/activities/${activity.ActivityId}`;
    };

    const onSave = function () {
        console.log(state);
        var data = {};

        if (state.activityName !== activity.ActivityName && state.activityName.length > 0) {
            data["activityName"] = state.activityName;
        };

        if (Object.keys(data).length === 0) {
            return;
        };

        fetchSave(data);
    };

    const fetchSave = async (data) => {
        const token = await currentUser.getIdToken(true);

        fetch(`${process.env.REACT_APP_WEB_API}/api/activities/${activity.ActivityId}/edit`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                window.location.href = `/activities/${activity.ActivityId}`;
            });
    };

    return (
        <Container fluid style={styles.mainContainer}>
            <br />

            <h1 style={styles.mainHeader}>{activity.ActivityName}</h1>
            <hr />

            <Container style={styles.contentContainer}>
                <label>Activity Name:</label>
                <div className="input-group mb-3">
                    <input type="text" style={styles.input} onChange={handleChange} name="activityName"></input>
                </div>
                <div className="d-flex flex-row justify-content-end">
                    <button onClick={onCancel} type="button" className="btn btn-primary" style={styles.cancelButton}>Cancel</button>
                    <button onClick={onSave} type="button" className="btn btn-primary" style={styles.saveButton}>Save</button>
                </div>
                <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
                </div>
            </Container>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 1250,
        paddingTop: 60,
        paddingBottom: 110
    },
    mainHeader: {
        textAlign: "left"
    },
    contentContainer: {
        maxWidth: 600
    },
    input: {
        width: "100%"
    },
    cancelButton: {
        width: 75,
        marginRight: 10
    },
    saveButton: {
        width: 75
    }
};

export default ActivityEdit;
