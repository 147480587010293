import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';
import SegmentModel from './models/SegmentModel';
import { useAuth } from "../../contexts/AuthContext";

function Segments() {

    const { currentUser } = useAuth();

    const [segments, setSegments] = useState([]);

    useEffect(() => {
        const fetchSegments = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/segments?activityTypeId=1`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return result.map(x => new SegmentModel(x));
        };

        fetchSegments().then(segments => {
            setSegments(segments);
        });
    }, []);

    return (
        <Container style={styles.mainContainer}>
            <br />
            <h1 style={styles.title}>Segments</h1>

            <hr />

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.tableHeaderIdentifier}>Id</th>
                        <th style={styles.tableHeaderName}>Name</th>
                        <th style={styles.tableHeaderDefault}>Distance (miles)</th>
                        <th style={styles.tableHeaderDefault}>Elevation (ft)</th>
                        <th style={styles.tableHeaderDefault}>Elevation Grade %</th>
                    </tr>
                </thead>
                <tbody>
                    {segments.map(segment => (
                        <tr key={segment.SegmentId}>
                            <td>{segment.SegmentId}</td>
                            <td style={styles.tableDataName}><Link style={styles.segmentLink} to={`/segments/${segment.SegmentId}`}>{segment.SegmentName}</Link></td>
                            <td style={styles.tableDataDefault}>{segment.Distance2dKilometers}</td>
                            <td style={styles.tableDataDefault}>{segment.ElevationAscentMeters}</td>
                            <td style={styles.tableDataDefault}>{segment.ElevationGradeAverage}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 1250,
        paddingTop: 60,
    },
    title: {
        textAlign: 'left'
    },
    tableHeaderIdentifier: {
        width: 50,
        textAlign: 'center'
    },
    tableHeaderName: {
        width: 'auto',
        textAlign: 'left'
    },
    tableHeaderDefault: {
        width: 175,
        textAlign: 'center'
    },
    tableDataName: {
        tableLayout: 'fixed',
        textAlign: 'left'
    },
    tableDataDefault: {
        width: 175,
        textAlign: 'center'
    },
    segmentLink: {
        color: '#0092CA'
    }
};

export default Segments;
