class ActivitySegmentModel {
    constructor(data, useMetricUnits) {
        this.ActivitySegmentId = data.ActivitySegmentId;
        this.ActivityId = data.ActivityId;
        this.SegmentId = data.SegmentId;
        this.SegmentName = data.SegmentName;
        this.Duration = new Date(data.DurationSeconds * 1000).toISOString().substr(11, 8);

        if (useMetricUnits) {
            this.Distance = Math.round((data.Distance2dKilometers + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationUphillMeters + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh + Number.EPSILON) * 100) / 100;
        }
        else {
            this.Distance = Math.round((data.Distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationUphillMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh * 0.621371 + Number.EPSILON) * 100) / 100;
        }

        this.LeaderboardPersonalRank = data.LeaderboardPersonalRank;
        this.IsLeaderboardPersonalRecord = data.IsLeaderboardPersonalRecord;
        this.IsLeaderboardRecord = data.IsLeaderboardRecord;
    }
}

export default ActivitySegmentModel;
