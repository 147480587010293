import SegmentLocationModel from "./SegmentLocationModel";

class SegmentModel {
    constructor(data, useMetricUnits) {
        this.SegmentId = data.SegmentId;
        this.SegmentName = data.SegmentName;
        this.GeoJson = JSON.parse(data.GeoJson);

        if (useMetricUnits) {
            this.Distance = Math.round((data.Distance2dKilometers + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationAscentMeters + Number.EPSILON) * 100) / 100;
        }
        else {
            this.Distance = Math.round((data.Distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationAscentMeters * 3.28084 + Number.EPSILON) * 100) / 100;
        }

        this.ElevationGrade = data.ElevationGradeAverage;

        this.Locations = [];
        JSON.parse(data.LocationJson).forEach(element => {
            this.Locations.push(new SegmentLocationModel(element));
        });
    }
};

export default SegmentModel;
