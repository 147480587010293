import { useEffect, useState } from "react";
import { Col, Card, Container, Row, Table } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroller';
import ActivityModel from "./models/ActivityModel";
import { useAuth } from "../../contexts/AuthContext";

function Dashboard() {

    const { currentUser } = useAuth();
    const [activities, setActivities] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);

    const fetchDashboard = async (page) => {
        const token = await currentUser.getIdToken(true);

        const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/dashboard?page=${page}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });

        const result = await data.json();
        return result.map(x => new ActivityModel(x, false));
    };

    const loader = <div className="loader" key="loader">Loading ...</div>;

    const handleLoadMore = async function (page) {
        const newActivities = await fetchDashboard(page - 1);

        if (newActivities.length > 0) {
            setActivities(activities => [...activities, ...newActivities]);
        }
        else {
            setHasMoreItems(false);
        }
    };

    return (
        <InfiniteScroll style={styles.mainContainer}
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasMoreItems}
            loader={loader}
        >
            <Row md={1} lg={2} className="g-4" style={styles.row}>
                {activities.map(activity => (
                    <Col key={activity.ActivityId}>
                        <Card style={styles.card} key={activity.ActivityId}>
                            <a style={styles.cardImageLink} href={`/activities/${activity.ActivityId}`}>
                                <Card.Img style={styles.cardImage} src={activity.SnapshotImage} />
                            </a>
                            <Card.Body>
                                <a style={styles.cardTitleLink} href={`/activities/${activity.ActivityId}`}>
                                    <Card.Title>{activity.ActivityName}</Card.Title>
                                </a>
                                <div style={{ display: "flex" }}>
                                    <div style={styles.cardText}>{activity.ActivityType}</div>
                                    <div style={{ width: 5 }}></div>
                                    <div style={styles.cardText}>|</div>
                                    <div style={{ width: 5 }}></div>
                                    <div style={styles.cardText}>{activity.Time}</div>
                                    <div style={{ width: 5 }}></div>
                                    <div style={styles.cardText}>|</div>
                                    <div style={{ width: 5 }}></div>
                                    <div style={styles.cardText}>{activity.UserName}</div>
                                </div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={styles.tableHeaderDefault}>Distance</th>
                                            <th style={styles.tableHeaderDefault}>Elevation</th>
                                            <th style={styles.tableHeaderDefault}>Duration</th>
                                            <th style={styles.tableHeaderDefault}>Speed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={styles.tableDataDefault}>{activity.Distance} mi</td>
                                            <td style={styles.tableDataDefault}>{activity.Elevation} ft</td>
                                            <td style={styles.tableDataDefault}>{activity.Duration}</td>
                                            <td style={styles.tableDataDefault}>{activity.MoveSpeedAverage} mph</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </InfiniteScroll>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 1050,
        paddingTop: 60,
        paddingBottom: 110
    },
    row: {
        paddingTop: 25
    },
    card: {
        maxWidth: 500,
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.15)"
    },
    cardTitleLink: {
        cursor: "pointer",
    },
    cardImageLink: {
        cursor: "pointer"
    },
    cardImage: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        objectFit: "contain",
        variant: "top"
    },
    cardText: {
        fontSize: "small"
    }
};

export default Dashboard;
