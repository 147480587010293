import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AccountModel from "./models/AccountModel";
import ProfileModel from "./models/ProfileModel";
import { useAuth } from "../../contexts/AuthContext";

function UserProfile() {

    const { currentUser, logout } = useAuth();
    const [account, setAccount] = useState({});
    const [profile, setProfile] = useState({});
    const [logoutError, setLogoutError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/profile`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = data.json();
            return result;
        };

        fetchUserProfile().then(userJson => {
            setAccount(new AccountModel(userJson));
            setProfile(new ProfileModel(userJson));
        });
    }, []);

    async function handleLogout() {
        setLogoutError("");
        await logout()
            .then(() => {
                navigate("/login");
            })
            .catch(error => {
                setLogoutError("Failed to log out");
                console.log(error);
            });
    }

    return (
        <Container style={styles.mainContainer}>
            <br />
            <h1 style={styles.mainHeader}>Profile</h1>
            <hr />

            <Container style={styles.contentContainer}>
                <table className="table table-striped table-hover">
                    <tbody>
                        <tr>
                            <th>User Id</th>
                            <td>{profile.UserId}</td>
                        </tr>
                        <tr>
                            <th>User Name</th>
                            <td>{profile.DisplayName}</td>
                        </tr>
                    </tbody>
                </table>
            </Container>

            <h1 style={styles.mainHeader}>Account</h1>
            <hr />

            <Container style={styles.contentContainer}>
                <table className="table table-striped table-hover">
                    <tbody>
                        <tr>
                            <th>User Id</th>
                            <td>{account.UserId}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{account.Email}</td>
                        </tr>
                        <tr>
                            <th>Email Verified</th>
                            <td>{account.EmailVerified}</td>
                        </tr>
                    </tbody>
                </table>
            </Container>

            <Link to="/profile/edit" className="btn btn-primary w-100 mt-3">
                Update Profile
            </Link>

            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>Log Out</Button>
            </div>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 800,
        paddingTop: 60,
        paddingBottom: 110
    },
    mainHeader: {
        textAlign: "left"
    },
    contentContainer: {
        maxWidth: 800
    },
};

export default UserProfile;
