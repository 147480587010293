import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import AchievementModel from "./models/AchievementModel";
import ActivityModel from "./models/ActivityModel";
import ActivitySegmentModel from "./models/ActivitySegmentModel";
import Highcharts from "highcharts";
import { useAuth } from "../../contexts/AuthContext";

// Mapbox
import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import bbox from "@turf/bbox";
import WebMercatorViewport from "@math.gl/web-mercator";

// Mapbox worker-loader workaround
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Activity = () => {

    const { activityId } = useParams();
    const { currentUser } = useAuth();

    const [viewState, setViewState] = useState({
        latitude: 44.986656,
        longitude: -93.258133,
        zoom: 10
    });

    const [activity, setActivity] = useState({});
    const [activitySegments, setActivitySegments] = useState([]);
    const [achievements, setAchievements] = useState([]);

    useEffect(() => {
        const fetchActivity = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/activities/${activityId}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return new ActivityModel(result, false);
        };

        const fetchActivitySegments = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/activities/${activityId}/segments`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return result.map(x => new ActivitySegmentModel(x, false));
        };

        fetchActivity().then(activity => {
            const viewport = fitViewportToFeature(activity.GeoJson);
            setViewState(viewport);
            setActivity(activity);
            loadActivityChart(activity.ChartJson);
        });

        fetchActivitySegments().then(activitySegments => {
            setActivitySegments(activitySegments);
            loadAchievements(activitySegments);
        });
    }, [activityId]);

    const loadAchievements = function (activitySegments) {
        var achievements = [];
        for (var activitySegment of activitySegments) {
            if (activitySegment.IsLeaderboardPersonalRecord) {
                var achievementData = {
                    ActivitySegmentId: activitySegment.ActivitySegmentId,
                    Details: `<strong>Personal Record</strong> on <a href="/activities/${activitySegment.ActivityId}/segments/${activitySegment.ActivitySegmentId}" style="color:#0092CA">${activitySegment.SegmentName}</a>`,
                    ImageUri: "/personal_achievement_gold.png"
                };
                var achievement = new AchievementModel(achievementData);
                achievements.push(achievement);
            }
        }
        setAchievements(achievements);
    };

    const loadActivityChart = function (chartJson, useMetricUnits) {
        var cadenceData = chartJson["cadence"];
        var elevationData = chartJson["elevation"];
        var heartRateData = chartJson["heartRate"];
        var powerData = chartJson["power"];
        var speedData = chartJson["speedKmh"];

        Highcharts.chart("container-highcharts-combined", {
            chart: {
                zoomType: "x",
                borderWidth: 2,
                borderColor: "#222529",
                height: 300
            },
            title: {
                text: null,
            },
            subtitle: {
                text: "."
            },
            credits: {
                enabled: false
            },
            xAxis: {
                crosshair: true
            },
            yAxis: [
                {
                    title: {
                        text: null,
                    },
                    labels: {
                        format: "{value}",
                        style: {
                            color: "#222529"
                        }
                    }
                },
                {
                    title: {
                        text: null,
                    },
                    labels: {
                        format: "{value} mph",
                        style: {
                            color: "#222529"
                        }
                    },
                    opposite: true
                },
                {
                    title: {
                        text: null,
                    },
                    labels: {
                        format: "{value} ft",
                        style: {
                            color: "#222529"
                        }
                    },
                    opposite: true,
                    visible: false,
                    min: 0
                }
            ],
            tooltip: {
                shared: true
            },
            series: [
                {
                    name: "Elevation",
                    type: "area",
                    yAxis: 2,
                    data: elevationData,
                    tooltip: {
                        valueSuffix: " ft"
                    },
                    color: "#222529",
                    fillOpacity: 0.85,
                    lineWidth: 2.0,
                },
                {
                    name: "Power",
                    type: "line",
                    yAxis: 0,
                    data: powerData,
                    tooltip: {
                        valueSuffix: " W"
                    },
                    color: "#3CBC3C",
                    lineWidth: 2.0,
                    visible: false
                },
                {
                    name: "Cadence",
                    type: "line",
                    yAxis: 0,
                    data: cadenceData,
                    tooltip: {
                        valueSuffix: " rpm"
                    },
                    color: "#BD3CBD",
                    lineWidth: 2.0,
                    visible: false
                },

                {
                    name: "Heart Rate",
                    type: "line",
                    yAxis: 0,
                    data: heartRateData,
                    tooltip: {
                        valueSuffix: " bpm"
                    },
                    color: "#FF0000",
                    lineWidth: 2.0
                },
                {
                    name: "Speed",
                    type: "line",
                    yAxis: 1,
                    data: speedData,
                    tooltip: {
                        valueSuffix: " mph"
                    },
                    color: "#0092CA",
                    lineWidth: 2.0
                }
            ]
        });
    };

    const fitViewportToFeature = (feature) => {
        // https://npm.io/package/@citydna/maps
        // https://github.com/visgl/react-map-gl/issues/1099
        const bounds = bbox(feature);
        const viewport = new WebMercatorViewport({ width: 800, height: 400 });
        const options = { padding: 100 };
        return viewport.fitBounds(
            [
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]],
            ],
            options
        );
    };

    return (
        <Container fluid style={styles.mainContainer}>
            <br />

            <Map style={styles.map} {...viewState} mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/streets-v9"
                onMove={event => setViewState(event.viewState)}>

                {Object.keys(activity).length !== 0 &&
                    <Source id="routes" type="geojson" data={activity.GeoJson}>
                        <Layer {...styles.mapboxLayerStyle} />
                    </Source>
                }
            </Map>

            <br />

            <div className="d-flex flex-column">
                <div>
                    <h1 style={{ textAlign: "left" }}>{activity.ActivityName}</h1>
                    <div className="d-flex">
                        <a style={styles.defaultLink} href={`/activities/${activity.ActivityId}/edit`}>Edit</a>
                        <p style={{ textAlign: "left", paddingLeft: "10px", paddingRight: 10 }}>|</p>
                        <p style={{ textAlign: "left" }}>{activity.ActivityType}</p>
                        <p style={{ textAlign: "left", paddingLeft: "10px", paddingRight: 10 }}>|</p>
                        <p style={{ textAlign: "left" }}>{activity.Time}</p>
                    </div>
                </div>
            </div>

            <hr />

            <Table striped hover>
                <thead>
                    <tr>
                        <th>Distance (miles)</th>
                        <th>Move Duration</th>
                        <th>Move Speed (mph)</th>
                        <th>Elevation Gain (ft)</th>
                        <th>Calories</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={styles.mainTableData}>{activity.Distance}</td>
                        <td style={styles.mainTableData}>{activity.MoveDuration}</td>
                        <td style={styles.mainTableData}>{activity.MoveSpeedAverage}</td>
                        <td style={styles.mainTableData}>{activity.Elevation}</td>
                        <td style={styles.mainTableData}>{activity.Calories}</td>
                    </tr>
                </tbody>
            </Table>

            <div className="d-flex flex-row">
                <Table striped hover style={{ width: 600 }}>
                    <thead>
                        <tr>
                            <th style={{ color: "white" }}>.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Elapsed Duration</th>
                            <td>{activity.ElapsedDuration}</td>
                        </tr>
                        <tr>
                            <th>Elapsed Speed Average</th>
                            <td>{activity.ElapsedSpeedAverage}</td>
                        </tr>
                        <tr>
                            <th>Elevation Min</th>
                            <td>{activity.ElevationMin}</td>
                        </tr>
                        <tr>
                            <th>Elevation Max</th>
                            <td>{activity.ElevationMax}</td>
                        </tr>
                    </tbody>
                </Table>

                <div style={{ width: 50 }}></div>

                <Table striped hover style={{ width: 600 }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>AVG</th>
                            <th>MAX</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Heart Rate</th>
                            <td>{activity.HeartRateAverage}</td>
                            <td>{activity.HeartRateMax}</td>
                        </tr>
                        <tr>
                            <th>Cadence</th>
                            <td>{activity.CadenceAverage}</td>
                            <td>{activity.CadenceMax}</td>
                        </tr>
                        <tr>
                            <th>Power</th>
                            <td>{activity.PowerAverage}</td>
                            <td>{activity.PowerMax}</td>
                        </tr>
                        <tr>
                            <th>Speed</th>
                            <td>{activity.MoveSpeedAverage}</td>
                            <td>{activity.MoveSpeedMax}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div>
                <hr />
                <div className="d-flex flex-column">
                    {achievements.map(achievement => (
                        <div key={achievement.ActivitySegmentId} className="d-flex flex-row">
                            <img style={{ width: 25, height: 25 }} src={achievement.ImageUri} alt="" />
                            <div style={{ width: 15 }}></div>
                            <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: achievement.Details }}></p>
                        </div>
                    ))}

                </div>
            </div>

            <hr />

            <figure className="highcharts-figure">
                <div id="container-highcharts-combined"></div>
            </figure>

            <hr />

            <h2>Segments</h2>

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.segmentTableHeaderName}>Segment Name</th>
                        <th style={styles.segmentTableHeaderDefault}>Distance (miles)</th>
                        <th style={styles.segmentTableHeaderDefault}>Duration</th>
                        <th style={styles.segmentTableHeaderDefault}>Move Speed</th>
                        <th style={styles.segmentTableHeaderDefault}>Elevation Gain</th>
                    </tr>
                </thead>
                <tbody>
                    {activitySegments.map(activitySegment => (
                        <tr key={activitySegment.ActivitySegmentId}>
                            <td style={styles.segmentTableDataName}><a style={styles.defaultLink} href={`/segments/${activitySegment.SegmentId}`}>{activitySegment.SegmentName}</a></td>
                            <td style={styles.segmentTableDataDefault}>{activitySegment.Distance}</td>
                            <td style={styles.segmentTableDataDefault}>{activitySegment.Duration}</td>
                            <td style={styles.segmentTableDataDefault}>{activitySegment.MoveSpeedAverage}</td>
                            <td style={styles.segmentTableDataDefault}>{activitySegment.Elevation}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

const styles = {
    mainContainer: {
        maxWidth: 1250,
        height: "100%",
        paddingTop: 60,
        paddingBottom: 110
    },
    title: {
        textAlign: "left"
    },
    map: {
        minHeight: 400,
        maxHeight: 400,
        border: "2px solid #222529"
    },
    defaultLink: {
        color: "#0092CA"
    },
    mainTableData: {
        fontSize: 24
    },
    mapboxLayerStyle: {
        id: "route-lines",
        type: "line",
        source: "route",
        "layout": {
            "line-join": "round",
            "line-cap": "round"
        },
        "paint": {
            "line-color": "#222529",
            "line-width": 4
        }
    },
    segmentTableHeaderDefault: {
        width: 175,
        textAlign: "center"
    },
    segmentTableHeaderDefaultName: {
        tableLayout: "fixed",
        textAlign: "left"
    },
    segmentTableDataDefault: {
        width: 175,
        textAlign: "center"
    },
    segmentTableDataName: {
        tableLayout: "fixed",
        textAlign: "left"
    }
};

export default Activity;
