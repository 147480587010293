import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";

// Mapbox
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// Mapbox worker-loader workaround
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const SegmentCreate = () => {

    const { currentUser } = useAuth();

    const [viewState, setViewState] = useState({
        latitude: 44.986656,
        longitude: -93.258133,
        zoom: 10
    });

    useEffect(() => {
        console.log("initialized");
    }, []);

    return (
        <Container style={styles.mainContainer}>
            <br />

            <Map style={styles.map} {...viewState} mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/streets-v9"
                onMove={event => setViewState(event.viewState)}>
            </Map>
        </Container>
    );
};

const styles = {
    mainContainer: {
        maxWidth: 1250,
        height: "100%",
        paddingTop: 60,
    },
    map: {
        minHeight: 800,
        maxHeight: "100%",
        height: "100%",
        border: "2px solid #222529"
    }
};

export default SegmentCreate;
