import React, { useRef, useState } from "react";
import { Alert, Button, Card, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function UserProfileEdit() {
    const displayNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const { updateUserEmail, updateUserPassword } = useAuth();
    const [updateError, setUpdateError] = useState("");
    const navigate = useNavigate();
    const [currentUser, loading, error] = useAuthState(auth);

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setUpdateError("Passwords do not match");
        }

        const promises = [];

        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateUserEmail(emailRef.current.value));
        }

        if (passwordRef.current.value) {
            promises.push(updateUserPassword(passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                navigate("/");
            })
            .catch(error => {
                setUpdateError("Failed to update account");
                console.log(error);
            });
    }

    return (
        <Container style={styles.mainContainer}>
            <br />
            <h1>Update Profile</h1>
            <hr />

            <Card>
                <Card.Body>
                    {updateError && <Alert variant="danger">{updateError}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="displayName" className="mb-3">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control type="email" ref={displayNameRef} required disabled defaultValue={currentUser.displayName}></Form.Control>
                        </Form.Group>
                        <Form.Group id="email" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required disabled defaultValue={currentUser.email}></Form.Control>
                        </Form.Group>
                        <Form.Group id="password" className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same"></Form.Control>
                        </Form.Group>
                        <Form.Group id="password-confirmation" className="mb-3">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmationRef} placeholder="Leave blank to keep the same"></Form.Control>
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-3" type="submit">
                            Update
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            <div className="w-100 text-center mt-2">
                <Link to="/">Cancel</Link>
            </div>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 800,
        paddingTop: 60,
    }
};

export default UserProfileEdit;
