import moment from "moment";

class ActivityModel {
    constructor(data, useMetricUnits) {
        this.SortId = data.SortId;
        this.UserId = data.UserId;
        this.UserName = data.UserName;
        this.ActivityId = data.ActivityId;
        this.ActivityName = data.ActivityName;
        this.ActivityType = data.ActivityType;

        this.Time = moment(data.Time).utc().format("MM/DD/YYYY hh:mm:ss a");
        this.Duration = new Date(data.MoveDurationSeconds * 1000).toISOString().substr(11, 8);

        if (useMetricUnits) {
            this.Distance = Math.round((data.Distance2dKilometers + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationAscentMeters + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh + Number.EPSILON) * 100) / 100;
        }
        else {
            this.Distance = Math.round((data.Distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationAscentMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh * 0.621371 + Number.EPSILON) * 100) / 100;
        }

        this.SnapshotImage = data.Base64Image;
    }
}

export default ActivityModel;
