import React from "react";
import { Container, Nav, Table } from "react-bootstrap";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../../firebase";
import { EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";

export default function Login() {
    const uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
            {
                provider: EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true
            }
        ],
        signInSuccessUrl: "/",
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                const token = authResult.user.accessToken;
                fetch(`${process.env.REACT_APP_WEB_API}/api/register`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token,
                    }
                })
                    .then(() => {
                        console.log(authResult.user);
                    });

                return true;
            }
        }
    };

    return (
        <Container style={styles.mainContainer}>

            <div>
                <h1 style={styles.sectionTitle}>Explore</h1>
                <p>Woo! Woo! Woo!</p>
            </div>



            <StyledFirebaseAuth uiCallback={ui => ui.disableAutoSignIn()} uiConfig={uiConfig} firebaseAuth={auth} />
        </Container>
    );
}

const styles = {
    mainContainer: {
        flex: 1,
        maxWidth: 800,
        paddingTop: 60,
    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: 44
        // fontFamily: 'Cera pro','Trebuchet MS',sans-serif;
    }
};
