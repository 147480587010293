import React, { useEffect, useState } from "react";
import { Container, Nav, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LeaderboardModel from "./models/LeaderboardModel";
import PersonalLeaderboardModel from "./models/PersonalLeaderboardModel";
import SegmentModel from "./models/SegmentModel";
import { useAuth } from "../../contexts/AuthContext";

// Mapbox
import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import bbox from "@turf/bbox";
import WebMercatorViewport from "@math.gl/web-mercator";

// Mapbox worker-loader workaround
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Segment = () => {

    const { currentUser } = useAuth();
    const { segmentId } = useParams();

    const [leaderboardModels, setLeaderboardModels] = useState([]);
    const [personalLeaderboardModels, setPersonalLeaderboardModels] = useState([]);
    const [segment, setSegment] = useState({});

    const [viewState, setViewState] = useState({
        latitude: 44.986656,
        longitude: -93.258133,
        zoom: 10
    });

    useEffect(() => {
        const fetchSegment = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/segments/${segmentId}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return new SegmentModel(result, false);
        };

        const fetchLeaderboard = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/segments/${segmentId}/leaderboard`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return result.map(x => new LeaderboardModel(x, false));
        };

        const fetchLeaderboardPersonal = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/segments/${segmentId}/leaderboard-personal`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return result.map(x => new PersonalLeaderboardModel(x, false));
        };

        fetchSegment().then(segment => {
            const viewport = fitViewportToFeature(segment.GeoJson);
            setViewState(viewport);
            setSegment(segment);
        });

        fetchLeaderboard().then(leaderboardModels => {
            setLeaderboardModels(leaderboardModels);
        });

        fetchLeaderboardPersonal().then(personalLeaderboardModels => {
            setPersonalLeaderboardModels(personalLeaderboardModels);
        });
    }, [segmentId]);

    const fitViewportToFeature = (feature) => {
        // https://npm.io/package/@citydna/maps
        // https://github.com/visgl/react-map-gl/issues/1099
        const bounds = bbox(feature);
        const viewport = new WebMercatorViewport({ width: 800, height: 400 });
        const options = { padding: 100 };
        return viewport.fitBounds(
            [
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]],
            ],
            options
        );
    };

    return (
        <Container style={styles.mainContainer}>
            <br />

            <Map style={styles.map} {...viewState} mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/streets-v9"
                onMove={event => setViewState(event.viewState)}>

                {Object.keys(segment).length !== 0 &&
                    <Source id="routes" type="geojson" data={segment.GeoJson}>
                        <Layer {...styles.layerStyle} />
                    </Source>
                }
            </Map>

            <br />

            <h1 style={styles.title}>{segment.SegmentName}</h1>

            <Nav>
                {Object.keys(segment).length !== 0 &&
                    segment.Locations.map((location, i) => (
                        <Nav.Link key={i} style={styles.navLink} href={location.LocationUri}>{i === 0 ? "" : "/"} {location.LocationName}</Nav.Link>
                    ))
                }
            </Nav>

            <hr />

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.tableHeaderName}>Name</th>
                        <th style={styles.tableHeaderDefault}>Distance (miles)</th>
                        <th style={styles.tableHeaderDefault}>Elevation (ft)</th>
                        <th style={styles.tableHeaderDefault}>Elevation Grade %</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={styles.tableDataName}>{segment.SegmentName}</td>
                        <td style={styles.tableDataDefault}>{segment.Distance}</td>
                        <td style={styles.tableDataDefault}>{segment.Elevation}</td>
                        <td style={styles.tableDataDefault}>{segment.ElevationGrade}</td>
                    </tr>
                </tbody>
            </Table>

            <hr />
            <h4>Personal Leaderboard</h4>

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.tableHeaderDefault}>Personal Rank</th>
                        <th style={styles.tableHeaderDefault}>Date</th>
                        <th style={styles.tableHeaderDefault}>Distance</th>
                        <th style={styles.tableHeaderDefault}>Duration</th>
                        <th style={styles.tableHeaderDefault}>Move Speed</th>
                    </tr>
                </thead>
                <tbody>
                    {personalLeaderboardModels.map(personalLeaderboardModel => (
                        <tr key={personalLeaderboardModel.ActivitySegmentId}>
                            <td style={styles.tableDataDefault}>{personalLeaderboardModel.LeaderboardPersonalRank}</td>
                            <td style={styles.tableDataDefault}>{personalLeaderboardModel.Time}</td>
                            <td style={styles.tableDataDefault}>{personalLeaderboardModel.Distance}</td>
                            <td style={styles.tableDataDefault}>{personalLeaderboardModel.Duration}</td>
                            <td style={styles.tableDataDefault}>{personalLeaderboardModel.MoveSpeedAverage}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h4>Leaderboard</h4>

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.tableHeaderDefault}>Rank</th>
                        <th style={styles.tableHeaderName}>User</th>
                        <th style={styles.tableHeaderDefault}>Date</th>
                        <th style={styles.tableHeaderDefault}>Distance</th>
                        <th style={styles.tableHeaderDefault}>Duration</th>
                        <th style={styles.tableHeaderDefault}>Move Speed</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderboardModels.map(leaderboardModel => (
                        <tr key={leaderboardModel.ActivitySegmentId}>
                            <td style={styles.tableDataDefault}>{leaderboardModel.LeaderboardRank}</td>
                            <td style={styles.tableDataName}>{leaderboardModel.UserDisplayName}</td>
                            <td style={styles.tableDataDefault}>{leaderboardModel.Time}</td>
                            <td style={styles.tableDataDefault}>{leaderboardModel.Distance}</td>
                            <td style={styles.tableDataDefault}>{leaderboardModel.Duration}</td>
                            <td style={styles.tableDataDefault}>{leaderboardModel.MoveSpeedAverage}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

const styles = {
    mainContainer: {
        maxWidth: 1250,
        height: "100%",
        paddingTop: 60,
    },
    title: {
        textAlign: "left"
    },
    map: {
        minHeight: 400,
        maxHeight: 400,
        border: "2px solid #222529"
    },
    navLink: {
        color: "#0092CA",
        paddingLeft: 0,
        paddingRight: 5
    },
    layerStyle: {
        id: "route-lines",
        type: "line",
        source: "route",
        "layout": {
            "line-join": "round",
            "line-cap": "round"
        },
        "paint": {
            "line-color": "#222529",
            "line-width": 4
        }
    },
    tableHeaderDefault: {
        width: 200,
        textAlign: "center"
    },
    tableHeaderName: {
        width: "auto",
        textAlign: "left"
    },
    tableDataDefault: {
        width: 200,
        textAlign: "center"
    },
    tableDataName: {
        width: "auto",
        textAlign: "left"
    }
};

export default Segment;
