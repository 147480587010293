import React, { useCallback, useRef, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { Buffer } from 'buffer';
import ActivityTypeModel from './models/ActivityTypeModel';
import { useAuth } from "../../contexts/AuthContext";

function UploadActivity() {

    const activityTypes = [
        new ActivityTypeModel({ "ActivityTypeId": 0, "ActivityType": "Select..." }),
        new ActivityTypeModel({ "ActivityTypeId": 1, "ActivityType": "Run" }),
        new ActivityTypeModel({ "ActivityTypeId": 2, "ActivityType": "Cycle" })
    ];

    const { currentUser } = useAuth();
    const [enableSubmit, setEnableSubmit] = useState(true);
    const [activityBytes, setActivityBytes] = useState(null);
    const [activityName, setActivityName] = useState('');
    const [activityType, setActivityType] = useState(activityTypes[0]);
    const navigate = useNavigate();

    const fetchUpload = async (activityName, activityTypeId, base64Result) => {
        const token = await currentUser.getIdToken(true);

        const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/upload/activity`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                activityName: activityName,
                activityTypeId: activityTypeId,
                data: base64Result
            })
        });

        const result = await data.json();
        return result;
    };

    const onDrop = useCallback(acceptedFile => {
        const reader = new FileReader();

        reader.onabort = () => {
            console.log('file reading was aborted');
        };

        reader.onerror = () => {
            console.log('file reading has failed');
        };

        reader.onload = async () => {
            const base64Result = Buffer.from(reader.result).toString('base64');
            setActivityBytes(base64Result);
        };

        if (activityName.length === 0) {
            setActivityName(acceptedFile[0].name.substr(0, acceptedFile[0].name.lastIndexOf(".")));
        }

        reader.readAsArrayBuffer(acceptedFile[0]);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: '.fit',
        maxFiles: 1
    });

    const style = useMemo(() => ({
        ...styles.default,
        ...(isFocused ? styles.focused : {}),
        ...(isDragAccept ? styles.accept : {}),
        ...(isDragReject ? styles.reject : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleChangeActivityName = (event) => {
        setActivityName(event.target.value);
    };

    const handleSelectActivityType = (event) => {
        setActivityType(activityTypes.find(x => x.ActivityTypeId === parseInt(event.target.value)));
    };

    const handleSubmit = async function (e) {
        e.preventDefault();

        setEnableSubmit(false);

        if (activityType.ActivityTypeId == 0) {
            alert('Select an activity type');
            setEnableSubmit(true);
            return;
        }

        if (activityBytes == null) {
            alert('Select an activity file');
            setEnableSubmit(true);
            return;
        }

        const result = await fetchUpload(activityName.trim(), activityType.ActivityTypeId, activityBytes);
        navigate(`/activities/${result.ActivityId}`);
    };

    return (
        <Container style={styles.mainContainer}>
            <br />
            <h1 style={styles.title}>Upload Activity</h1>
            <hr />
            <br />

            <Container style={{ maxWidth: 800 }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} id="activityName" className="mb-3">
                        <Form.Label column sm={2}>Activity Name</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" required defaultValue={activityName} onChange={handleChangeActivityName}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} id="activityName" className="mb-3">
                        <Form.Label column sm={2}>Activity Type</Form.Label>
                        <Col sm={10}>
                            <Form.Select required onChange={handleSelectActivityType}>
                                {activityTypes.map(activityType => (
                                    <option key={activityType.ActivityTypeId} value={activityType.ActivityTypeId}>{activityType.ActivityType}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group>
                        {activityBytes == null &&
                            <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <p style={styles.dropzoneContent}>Drag 'n' drop file here or click to select</p>
                            </div>
                        }
                        {activityBytes != null &&
                            <div style={{ width: '100%' }}>
                                <p style={{ textAlign: 'center' }}>File selected.</p>
                            </div>
                        }
                    </Form.Group>
                    <Button disabled={!enableSubmit} className="w-100 mt-3" type="submit">
                        Upload
                    </Button>
                </Form>
            </Container>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 1250,
        paddingTop: 60,
    },
    default: {
        height: 150,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        marginBottom: 15,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#EEEEEE',
        borderStyle: 'dashed',
        backgroundColor: '#FAFAFA',
        color: '#BDBDBD',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    accept: {
        borderColor: '#00e676'
    },
    focused: {
        borderColor: '#2196f3'
    },
    reject: {
        borderColor: '#ff1744'
    },
    dropzoneContent: {
        margin: 0
    },
    dropdown: {
        border: '1px solid #CED4dA',
        textAlign: 'left'
    }
};

export default UploadActivity;
