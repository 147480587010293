import moment from "moment";

class LeaderboardModel {
    constructor(data, useMetricUnits) {
        this.UserId = data.UserId;
        this.UserDisplayName = data.UserDisplayName;

        this.ActivityId = data.ActivityId;
        this.ActivitySegmentId = data.ActivitySegmentId;
        this.Duration = new Date(data.DurationSeconds * 1000).toISOString().substr(11, 8);
        this.Time = moment(data.Time).format("YYYY-MM-DD h:mm:ss a");

        if (useMetricUnits) {
            this.Distance = Math.round((data.Distance2dKilometers + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh + Number.EPSILON) * 100) / 100;
        }
        else {
            this.Distance = Math.round((data.Distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh * 0.621371 + Number.EPSILON) * 100) / 100;
        }

        this.LeaderboardRank = data.LeaderboardRank;
    }
};

export default LeaderboardModel;
