import { Container, Nav, Navbar } from "react-bootstrap";

function HorizonNavFooter() {

    return (
        <Navbar fixed="bottom" bg="dark" variant="dark" expand="lg">
            <Container style={styles.container}>
                <Navbar.Brand href="/">
                    <img src="/logo35h.png" width="auto" height="35" className="d-inline-block align-top" alt="" />
                </Navbar.Brand>
                <Nav>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/legal/privacy-policy">Privacy</Nav.Link>
                    <Nav.Link href="/legal/terms-of-service">Terms</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center"
    }
};

export default HorizonNavFooter;
