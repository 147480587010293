import moment from "moment";

class ActivityModel {
    constructor(data, useMetricUnits) {
        this.User = data.DisplayName;
        this.ActivityId = data.ActivityId;
        this.ActivityName = data.ActivityName;
        this.ActivityType = data.ActivityType;

        this.Time = moment(data.Time).format("dddd, MMMM Do YYYY [at] h:mm:ss a");
        this.MoveDuration = new Date(data.MoveDurationSeconds * 1000).toISOString().substr(11, 8);
        this.ElapsedDuration = new Date(data.ElapsedDurationSeconds * 1000).toISOString().substr(11, 8);

        if (useMetricUnits) {
            this.Distance = Math.round((data.Distance2dKilometers + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationUphillMeters + Number.EPSILON) * 100) / 100;
            this.ElevationMin = Math.round((data.ElevationMinMeters + Number.EPSILON) * 100) / 100;
            this.ElevationMax = Math.round((data.ElevationMaxMeters + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh + Number.EPSILON) * 100) / 100;
            this.MoveSpeedMax = Math.round((data.MoveSpeedMaxKmh + Number.EPSILON) * 100) / 100;
            this.ElapsedSpeedAverage = Math.round((data.ElapsedSpeedAverage2dKmh + Number.EPSILON) * 100) / 100;
        }
        else {
            this.Distance = Math.round((data.Distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
            this.Elevation = Math.round((data.ElevationUphillMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            this.ElevationMin = Math.round((data.ElevationMinMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            this.ElevationMax = Math.round((data.ElevationMaxMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            this.MoveSpeedAverage = Math.round((data.MoveSpeedAverageKmh * 0.621371 + Number.EPSILON) * 100) / 100;
            this.MoveSpeedMax = Math.round((data.MoveSpeedMaxKmh * 0.621371 + Number.EPSILON) * 100) / 100;
            this.ElapsedSpeedAverage = Math.round((data.ElapsedSpeedAverage2dKmh * 0.621371 + Number.EPSILON) * 100) / 100;
        }

        this.ChartJson = JSON.parse(data.ChartJson);
        this.GeoJson = JSON.parse(data.GeoJson);

        this.Calories = 0;

        this.CadenceAverage = data.CadenceAverage;
        this.CadenceMax = data.CadenceMax;
        this.HeartRateAverage = data.HeartRateAverage;
        this.HeartRateMax = data.HeartRateMax;
        this.PowerAverage = data.PowerAverage;
        this.PowerMax = data.PowerMax;
    }
}

export default ActivityModel;
