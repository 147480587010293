import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import ActivityTypeModel from "./models/ActivityTypeModel";
import SegmentModel from "./models/SegmentModel";
import "./segment-explore.css";

// Mapbox
// import Map, { Source, Layer } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// Mapbox worker-loader workaround
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const SegmentExploreMobile = () => {

    // const [viewState, setViewState] = useState({
    //     latitude: 44.986656,
    //     longitude: -93.258133,
    //     zoom: 10
    // });

    const activityTypes = [
        new ActivityTypeModel({ "ActivityTypeId": 1, "ActivityType": "Run" }),
        new ActivityTypeModel({ "ActivityTypeId": 2, "ActivityType": "Cycle" })
    ];

    const [activityType, setActivityType] = useState(activityTypes[0]);

    useEffect(() => {
        console.log(activityType);

        const fetchSegments = async () => {
            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/segmentsx?activityTypeId=${activityType.ActivityTypeId}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });

            const result = await data.json();
            return result.map(x => new SegmentModel(x));
        };

        fetchSegments().then(segments => {
            loadSegmentMapBox(segments, false);
        });
    }, [activityType]);

    const handleSelectActivityType = (eventKey, event) => {
        console.log(activityTypes.find(x => x.ActivityTypeId === parseInt(eventKey)));
        setActivityType(activityTypes.find(x => x.ActivityTypeId === parseInt(eventKey)));
    };

    const createFeatureCollection = function (segments, useMetricUnits) {
        var features = [];
        segments.forEach(x => {
            var featureJson = x.GeoJson;
            featureJson.properties.segmentId = x.SegmentId;
            featureJson.properties.segmentName = x.SegmentName;

            if (useMetricUnits) {
                featureJson.properties.distance = Math.round((featureJson.properties.distance2dKilometers + Number.EPSILON) * 100) / 100;
                featureJson.properties.elevationAscent = Math.round((featureJson.properties.elevationAscentMeters + Number.EPSILON) * 100) / 100;
            }
            else {
                featureJson.properties.distance = Math.round((featureJson.properties.distance2dKilometers * 0.621371 + Number.EPSILON) * 100) / 100;
                featureJson.properties.elevationAscent = Math.round((featureJson.properties.elevationAscentMeters * 3.28084 + Number.EPSILON) * 100) / 100;
            }

            features.push(featureJson);
        });

        return {
            "type": "FeatureCollection",
            "features": features
        };
    };

    const loadSegmentMapBox = function (segments, useMetricUnits) {
        var centerCoordinate = [-93.269536, 44.977041];
        // if (self.currentLocationLatitude != null && self.currentLocationLongitude != null) {
        //     centerCoordinate = [self.currentLocationLongitude, self.currentLocationLatitude]
        // }

        const featureCollection = createFeatureCollection(segments, useMetricUnits);

        mapboxgl.accessToken = "pk.eyJ1IjoiZXdpZ2dpbjEwMjkzODQ3NTYiLCJhIjoiY2txYjNzbXl2MGE3dDJ2cDl2bzdyOHE4eCJ9.hgvRngxLUIFsJSxpNb2Yhw";

        const map = new mapboxgl.Map({
            container: "mapbox-segment-explore",
            style: "mapbox://styles/mapbox/streets-v11",
            center: centerCoordinate,
            zoom: 12,
            scrollZoom: true
        });

        //-------------------------------------------------------------------------------------------------
        // Display a popup for the provided feature
        //-------------------------------------------------------------------------------------------------
        map.on("load", () => {
            map.addLayer({
                id: "locations",
                type: "line",
                source: {
                    type: "geojson",
                    data: featureCollection
                },
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#222529",
                    "line-width": 4
                }
            });

            // Disable map rotation using right click + drag
            map.dragRotate.disable();

            // Disable map rotation using touch rotation gesture
            map.touchZoomRotate.disableRotation();

            // Add custom markers for each segment to the map
            createSegmentMarkers(featureCollection);
        });

        //-------------------------------------------------------------------------------------------------
        // Create a marker on the map for each segment
        //-------------------------------------------------------------------------------------------------
        function createSegmentMarkers(featureCollection) {
            for (const feature of featureCollection.features) {

                // Create a div element for the segment marker
                const segmentMarkerElement = document.createElement("div");
                segmentMarkerElement.id = `segment-marker-{segment.properties.id}`;
                segmentMarkerElement.className = "marker";

                // Create a marker using the div element
                new mapboxgl.Marker(segmentMarkerElement, { offset: [0, -21] })
                    .setLngLat(feature.geometry.coordinates[0])
                    .addTo(map);

                segmentMarkerElement.addEventListener("click", e => {
                    // Adjust the map to center on the clicked segment
                    moveMapToFeature(feature);

                    // Close all other popups and display popup for clicked segment
                    createPopup(feature);

                    // Highlight the segment listing in the sidebar (and remove highlight for all other listings)
                    const sidebar = document.getElementById("segment-sidebar-listings");
                    e.stopPropagation();

                    sidebar.querySelectorAll(".active").forEach(x => {
                        x.classList.remove("active");
                    });

                    sidebar.querySelectorAll(`[id=segment-listing-${feature.properties.segmentId}]`).forEach(x => {
                        x.classList.add("active");
                    });
                });
            }
        }

        //-------------------------------------------------------------------------------------------------
        // Move the map to the feature coordinates (provided a center point)
        //-------------------------------------------------------------------------------------------------
        function moveMapToFeature(feature, zoom = 10) {
            map.flyTo({
                center: feature.geometry.coordinates[0]
                // zoom: zoom
            });
        }

        //-------------------------------------------------------------------------------------------------
        // Display a popup for the provided feature
        //-------------------------------------------------------------------------------------------------
        function createPopup(feature) {
            // Remove existing popups
            document.querySelectorAll(".mapboxgl-popup").forEach(function (popup) {
                popup.remove();
            });

            var details = null;
            if (useMetricUnits) {
                details = `${feature.properties.distance} km &middot; ${feature.properties.elevationAscent} m &middot; ${feature.properties.elevationGradeAverage} %`;
            }
            else {
                details = `${feature.properties.distance} mi &middot; ${feature.properties.elevationAscent} ft &middot; ${feature.properties.elevationGradeAverage} %`;
            }

            new mapboxgl.Popup({ className: "mapbox-popup", closeOnClick: true })
                .setLngLat(feature.geometry.coordinates[0])
                .setHTML(`
                    <div class="mapbox-popup-header">${feature.properties.segmentName}</div>
                    <div class="mapbox-popup-content">${details}</div>
                    <a href="/segments/${feature.properties.segmentId}" class="btn btn-sm btn-light mapbox-popup-button">Details</a>`)
                .addTo(map);
        }
    };

    return (
        <Container style={styles.mainContainer}>
            <div>
                <Dropdown onSelect={handleSelectActivityType}>
                    <Dropdown.Toggle className="sidebar-dropdown-button" variant="light" size="sm">
                        {activityType?.ActivityType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {activityTypes.map(activityType => (
                            <Dropdown.Item key={activityType.ActivityTypeId} eventKey={activityType.ActivityTypeId}>{activityType.ActivityType}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                    {/* <button className="btn btn-light btn-sm dropdown-toggle sidebar-dropdown-button" type="button" data-bs-toggle="dropdown"></button> */}
                    {/* <ul className="dropdown-menu"> */}
                    {/* <li>
                                <div className="dropdown-item" data-bind="text: ActivityType, click: $root.clickedActivityType"></div>
                            </li> */}
                    {/* </ul> */}
                </Dropdown>
            </div>

            <div id="mapbox-segment-explore" className="mapbox-segments" style={styles.mapboxMobile}></div>
            {/* <Map style={styles.map} {...viewState} mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/streets-v9"
                    onMove={event => setViewState(event.viewState)}>

                    <Source id="routes" type="geojson" data={featureCollection}>
                        <Layer {...styles.layerStyle}/>
                    </Source>
                </Map> */}
        </Container>
    );
};

const styles = {
    mainContainer: {
        fluid: 1,
        height: "-webkit-calc(100vh - 110px)",
        maxWidth: '100%',
        paddingTop: 60,
    },
    map: {
        // minHeight: '100vh',
        // maxHeight: "100vh",
        // height: "100px",
        // border: "2px solid #222529"
    },
    layerStyle: {
        id: "route-lines",
        type: "line",
        source: "route",
        "layout": {
            "line-join": "round",
            "line-cap": "round"
        },
        "paint": {
            "line-color": "#222529",
            "line-width": 8
        }
    },
    mapbox: {
        width: "75%",
        left: "25%"
    },
    mapboxMobile: {
        width: "100%",
        left: "0%"
    },
    sidebar: {
        width: "25%"
    },
    sidebarMobile: {
        width: "0%"
    }
};

export default SegmentExploreMobile;
