import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

import About from "./components/legal/About";
import Activity from "./components/activity/Activity";
import ActivityEdit from "./components/activity-edit/ActivityEdit";
import ActivitySegment from "./components/activity-segment/ActivitySegment";
import AuthProvider from "./contexts/AuthContext";
import Dashboard from "./components/dashboard/Dashboard";
import Home from "./components/home/Home";
import HorizonNav from "./components/HorizonNav";
import HorizonNavFooter from "./components/HorizonNavFooter";
import Login from "./components/login/Login";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import RequireAuth from "./components/RequireAuth";
import Segment from "./components/segment/Segment";
import SegmentCreate from "./components/segment-create/SegmentCreate";
import SegmentExplore from "./components/segment-explore/SegmentExplore";
import SegmentExploreMobile from "./components/segment-explore-mobile/SegmentExploreMobile";
import Segments from "./components/segments/Segments";
import Terms from "./components/legal/Terms";
import UnderConstruction from "./components/under-construction/UnderConstruction";
import UploadActivity from "./components/upload-activity/UploadActivity";
import UploadSegment from "./components/upload-segment/UploadSegment";
import UserProfile from "./components/user-profile/UserProfile";
import UserProfileEdit from "./components/user-profile-edit/UserProfileEdit";

function App() {
    return (
        <Container style={{ maxWidth: '100vw' }}>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/about" element={<About />} />
                        <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/legal/terms-of-service" element={<Terms />} />
                        <Route path="/login" element={<Login />} />

                        <Route path="/" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <Dashboard />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/activities/:activityId" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <Activity />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/activities/:activityId/edit" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <ActivityEdit />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/activities/:activityId/segments/:activitySegmentId" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <ActivitySegment />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/dashboard" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <Dashboard />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/profile" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <UserProfile />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/profile/edit" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <UserProfileEdit />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/segments" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <Segments />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/segments/:segmentId" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <Segment />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/segments/create" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <SegmentCreate />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/segments/explore" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <SegmentExplore />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/segments/explore-mobile" element={
                            <SegmentExploreMobile />
                        } />

                        <Route path="/upload/activity" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <UploadActivity />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />

                        <Route path="/upload/segment" element={
                            <RequireAuth redirectTo={"/login"}>
                                <HorizonNav />
                                <UploadSegment />
                                <HorizonNavFooter />
                            </RequireAuth>
                        } />
                    </Routes>
                </AuthProvider>
            </Router>
        </Container>

        //<UnderConstruction />
    );
}

export default App;
