import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import ActivitySegmentModel from "./models/ActivitySegmentModel";
import { useAuth } from "../../contexts/AuthContext";

// Mapbox
import mapboxgl from "mapbox-gl";

// Mapbox worker-loader workaround
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function ActivitySegment() {

    const { currentUser } = useAuth();
    const { activityId, activitySegmentId } = useParams();
    const [activitySegment, setActivitySegment] = useState({});

    useEffect(() => {
        const fetchActivitySegment = async () => {
            const token = await currentUser.getIdToken(true);

            const data = await fetch(`${process.env.REACT_APP_WEB_API}/api/activities/${activityId}/segments/${activitySegmentId}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            });

            const result = await data.json();
            return new ActivitySegmentModel(result);
        };

        fetchActivitySegment().then(activitySegment => {
            setActivitySegment(activitySegment);
            loadActivitySegmentMapBox(activitySegment);
        });
    }, [activityId, activitySegmentId]);

    const loadActivitySegmentMapBox = function (activitySegment) {
        mapboxgl.accessToken = "pk.eyJ1IjoiZXdpZ2dpbjEwMjkzODQ3NTYiLCJhIjoiY2txYjNzbXl2MGE3dDJ2cDl2bzdyOHE4eCJ9.hgvRngxLUIFsJSxpNb2Yhw";

        const coordinate_center = activitySegment.SegmentGeoJson.geometry.coordinates[0];

        var map = new mapboxgl.Map({
            container: "mapbox-activity-segment",
            style: "mapbox://styles/mapbox/streets-v11",
            center: coordinate_center,
            zoom: 13
        });

        map.on("load", () => {
            map.addSource("route", {
                "type": "geojson",
                "data": activitySegment.SegmentGeoJson
            });
            map.addSource("activitySegment", {
                "type": "geojson",
                "data": activitySegment.ActivitySegmentGeoJson
            });
            map.addLayer({
                "id": "route",
                "type": "line",
                "source": "route",
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": "#386890",
                    "line-width": 8
                }
            });
            map.addLayer({
                "id": "activitySegment",
                "type": "line",
                "source": "activitySegment",
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": "#dc143c",
                    "line-width": 4
                }
            });
        });

        const coordinates = activitySegment.SegmentGeoJson.geometry.coordinates;

        // Create a "LngLatBounds" with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
        );

        // Extend the "LngLatBounds" to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            bounds.extend(coord);
        }

        map.fitBounds(bounds, {
            padding: 30
        });
    };

    return (
        <Container fluid style={styles.mainContainer}>
            <br />
            <div id="mapbox-activity-segment" style={{ width: "auto", height: 600 }}></div>
            <br />

            <h1 style={styles.mainHeader}>{activitySegment.SegmentName}</h1>
            <div className="d-flex">
                <a style={styles.defaultLink} href={`/activities/${activitySegment.ActivityId}`}>{activitySegment.ActivityName}</a>
                <p style={{ textAlign: "left", paddingLeft: "10px", paddingRight: 10 }}>|</p>
                <p style={{ textAlign: "left" }}>{activitySegment.ActivityTime}</p>
            </div>
            <hr />

            <Table striped hover>
                <thead>
                    <tr>
                        <th style={styles.tableHeaderDefault}>Time</th>
                        <th style={styles.tableHeaderDefault}>Distance (mi)</th>
                        <th style={styles.tableHeaderDefault}>Duration</th>
                        <th style={styles.tableHeaderDefault}>Move Speed (mph)</th>
                        <th style={styles.segmentTableHeaderDefault}>Elevation Gain (ft)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={styles.tableDataDefault}>{activitySegment.ActivitySegmentTime}</td>
                        <td style={styles.tableDataDefault}>{activitySegment.Distance}</td>
                        <td style={styles.tableDataDefault}>{activitySegment.Duration}</td>
                        <td style={styles.tableDataDefault}>{activitySegment.MoveSpeedAverage}</td>
                        <td style={styles.tableDataDefault}>{activitySegment.Elevation}</td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
}

const styles = {
    mainContainer: {
        maxWidth: 1050,
        paddingTop: 60,
        paddingBottom: 110
    },
    mainHeader: {
        textAlign: "left"
    },
    tableHeaderDefault: {
        width: 175,
        textAlign: "center"
    },
    segmentTableHeaderDefaultName: {
        tableLayout: "fixed",
        textAlign: "left"
    },
    tableDataDefault: {
        width: 175,
        textAlign: "center"
    },
    segmentTableDataName: {
        tableLayout: "fixed",
        textAlign: "left"
    }
};

export default ActivitySegment;
