import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

function HorizonNav() {

    return (
        <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <img src="/logo35h.png" width="auto" height="35" className="d-inline-block align-top" alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        <NavDropdown title="Segments" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/segments/explore">Explore</NavDropdown.Item>
                            <NavDropdown.Item href="/segments">Search</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/segments/create">Create</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Upload" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/upload/activity">Activity</NavDropdown.Item>
                            <NavDropdown.Item href="/upload/segment">Segment</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/profile">Profile</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default HorizonNav;
